// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as PrismJs from "../utils/PrismJs.bs.js";
import * as Markdown from "../utils/Markdown.bs.js";
import * as Js_string from "../../../../node_modules/rescript/lib/es6/js_string.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

import "./MarkdownBlock.css"
;

function randomId(param) {
  var randomComponent = Js_string.substr(2, Math.random().toString());
  return "markdown-block-" + randomComponent;
}

function profileClasses(profile) {
  if (profile) {
    return "markdown-block__area-of-text ";
  } else {
    return "markdown-block__permissive ";
  }
}

function markdownBlockClasses(profile, className) {
  var defaultClasses = "markdown-block " + (
    profile ? "markdown-block__area-of-text " : "markdown-block__permissive "
  );
  if (className !== undefined) {
    return defaultClasses + className;
  } else {
    return defaultClasses;
  }
}

function MarkdownBlock(Props) {
  var markdown = Props.markdown;
  var className = Props.className;
  var profile = Props.profile;
  var match = React.useState(function () {
        return randomId(undefined);
      });
  var id = match[0];
  React.useEffect((function () {
          PrismJs.highlightAllUnder(id);
        }), [markdown]);
  return React.createElement("div", {
              className: markdownBlockClasses(profile, className),
              id: id,
              dangerouslySetInnerHTML: Markdown.toSafeHTML(markdown, profile)
            });
}

function makeFromJson(props) {
  var markdown = Json_decode.field("markdown", Json_decode.string, props);
  var className = Json_decode.optional((function (param) {
          return Json_decode.field("className", Json_decode.string, param);
        }), props);
  return MarkdownBlock({
              markdown: markdown,
              className: className,
              profile: /* Permissive */0
            });
}

var make = MarkdownBlock;

export {
  randomId ,
  profileClasses ,
  markdownBlockClasses ,
  make ,
  makeFromJson ,
}
/*  Not a pure module */
